import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Frontmatter`}</h1>
    <p>{`Frontmatter is the stuff you put at the very top of your markdown files to control everything from the title, page description,
images, and SEO. On this site, we also use it to control what appears in the global header navigation, as well as how the sidebar
gets rendered in each section. It's therefore quite important to know how to structure your frontmatter so that your file appears
in the place you want it to.`}</p>
    <h2>{`Title`}</h2>
    <div {...{
      "className": "gatsby-code-title prism-code-title"
    }}>{`title-example.mdx`}</div>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`title[String] - Display title used for Header,Sidenav, and SEO.  
title: Frontmatter
`}</code></pre>
    <Box sx={{
      backgroundColor: 'success',
      padding: 3,
      marginBottom: 4
    }} mdxType="Box">
      <h3>{`The Title Rule`}</h3>
      <p>{`This specifies that we read and display the title in the `}<strong parentName="p">{`frontmatter first`}</strong>{`, then the `}<strong parentName="p">{`first h1 (#) in the file`}</strong>{`,
then we `}<strong parentName="p">{`fall back to the name of the file`}</strong>{`.`}</p>
    </Box>
    <p>{`The "title" attribute is used for the title meta attribute for SEO. The title rule still applies - it is global.`}</p>
    <h2>{`Order`}</h2>
    <p>{`This is a frontmatter attribute unique to this site which we use to define the order in which different pages appear
in the sidebar navigation.`}</p>
    <div {...{
      "className": "gatsby-code-title prism-code-title"
    }}>{`order-example.mdx`}</div>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`order[Integer] - The order in which this file is displayed on the sidenav.
order: 0
`}</code></pre>
    <Box sx={{
      backgroundColor: 'success',
      padding: 3,
      marginBottom: 4
    }} mdxType="Box">
      <h3>{`Ordering`}</h3>
      <p>{`Order in the sidenav is based on lowest number (TOP) to highest (BOTTOM). Files that don't have an order are applied AFTER files that have order in alphabetical order. If this file isn't rendered on the sidenav (ie. Top section files) this won't matter.`}</p>
    </Box>
    <p>{`The "order" is `}<strong parentName="p">{`relative to the current directory`}</strong>{`, so it's not possible to take a page deeper in the directory
and put it on the top level of the sidebar. For example, `}<inlineCode parentName="p">{`/work_with_us/bounties/test.mdx`}</inlineCode>{` with an order of 0 will
just appear at the top of the `}<inlineCode parentName="p">{`bounties`}</inlineCode>{` dropdown in the sidenav and not at the top of the `}<inlineCode parentName="p">{`work_with_us`}</inlineCode>{` sidenav.`}</p>
    <h2>{`Language Selector`}</h2>
    <p>{`This is a frontmatter attribute unique to this site which defines whether users will be able to see the different languages available for
the particular page they are currently on.`}</p>
    <div {...{
      "className": "gatsby-code-title prism-code-title"
    }}>{`language-example.mdx`}</div>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`hideLanguageSelector[Boolean] - Language selectors render by default, setting this to true for your page allows you to hide it.
hideLanguageSelector: false
`}</code></pre>
    <h2>{`Header Navigation`}</h2>
    <p>{`The header options are an easy way of adding internal files to the global header. Files specified in header.mdx gets applied AFTER these.
Files are organised in the header based on lowest number (LEFT) to highest (RIGHT). "Home" is ALWAYS first, then files with headerOrder,
then links in header.mdx.`}</p>
    <div {...{
      "className": "gatsby-code-title prism-code-title"
    }}>{`header-example.mdx`}</div>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`header[String] - Whether this File should be rendered in the Header Navigation.
header: true

headerOrder[Integer] - The order in which this file will be displayed on the header.
headerOrder: 0
`}</code></pre>
    <h2>{`SEO`}</h2>
    <p>{`So you want your page to rank well, and have a nice image and description when you share it on social media to show your friends
the awesome work you've done? We've got you covered.`}</p>
    <div {...{
      "className": "gatsby-code-title prism-code-title"
    }}>{`image-example.mdx`}</div>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`featuredImage[String] - Image to use when a link is shared (ie. Twitter/Facebook)
featuredImage: "content/images/test.png"
`}</code></pre>
    <Box sx={{
      backgroundColor: 'warning',
      padding: 3,
      marginBottom: 4
    }} mdxType="Box">
      <p>{`The "image" attribute `}<strong parentName="p">{`must`}</strong>{` be a path to the images in `}<inlineCode parentName="p">{`content/images/`}</inlineCode></p>
    </Box>
    <div {...{
      "className": "gatsby-code-title prism-code-title"
    }}>{`keywords-example.mdx`}</div>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`keywords[String] - Comma seperated keywords used for SEO
keywords: "cool, sweet, awesome"
`}</code></pre>
    <div {...{
      "className": "gatsby-code-title prism-code-title"
    }}>{`description-example.mdx`}</div>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`description[String] - Description of this page for SEO.
description: "The most complete guide to writing frontmatter in mdx files that you've ever read."
`}</code></pre>
    <p>{`The "description" attribute can ALSO be used for the excerpt that gets rendered and indexed for searching articles on the site.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      